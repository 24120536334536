<template>
  <el-card>
    <div class="flex-between mb20">
      <h3>名称：{{ lawName }}</h3>
      <el-button
        size="small"
        @click="back">
        返 回
      </el-button>
    </div>
    <div class="filter-wrap flex-between">
      <div class="flex-start search-filter-wrap">
        <el-input
          v-model="listQuery.SearchStr"
          width="180"
          placeholder="请输入内容"
          class="mr10"
          clearable
          @clear="handleFilter"
        />
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="handleFilter"
        >
          搜索
        </el-button>
      </div>
      <div class="text-right">
        <el-button
          class="mr10"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="importDialogShow"
        >
          导 入
        </el-button>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="openDialog(1)"
        >
          新增
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      border
      fit
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="章节"
        :show-overflow-tooltip="true"
        prop="code"
        width="200px"
        header-align="center"
        align="left"
      />
      <el-table-column
        :show-overflow-tooltip="true"
        header-align="left"
        align="left"
        label="内容"
        prop="content"
      />
      <el-table-column
        label="解读"
        prop="unScrambleCount"
        width="80px"
      />
      <el-table-column
        label="案例"
        prop="caseCount"
        width="80px"
      />
      <el-table-column
        label="关联法规数"
        prop="relationLawCount"
        width="80px"
      />
      <el-table-column
        label="操作"
        width="120px"
      >
        <template slot-scope="{row}">
          <el-link
            class="mr10"
            type="primary"
            @click="openDialog(2,row)"
          >
            <el-tooltip
              content="编辑"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="delLaw(row.id)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.pageSize"
      @pagination="getList"
    />
    <LawsDetailSave
      ref="lawsSave"
      @handleFilter="handleFilter"
    />
    <ImportLawsDetail
        ref="importLawsDetail"
        @handleFilter="handleFilter"
    />
  </el-card>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import { error, success } from '@core/utils/utils'
import {DeleteLawDetailsByIds, GetLawById, LawDetailsPage} from '@/api/knowledgeBase/laws.js'
import LawsDetailSave from "@/views/knowledge/laws/LawsDetailSave.vue";
import ImportLawsDetail from "@/views/knowledge/laws/ImportLawsDetail.vue";
export default {
  components: {
    pagination,
    LawsDetailSave,
    ImportLawsDetail,
  },

  data() {
    return {
      loading: false,
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        lawCode: this.$route.query.c,
      },
      lawId: Number(this.$route.query.id),
      tableData: [],
      //  1征集意见 2正式使用 停用
      statusObj: {
        1: '征集意见',
        2: '正式使用',
        3: '停用',
      },
      lawName: '',
    }
  },
  created() {
    this.getLawById()
    this.getList()
  },
  mounted() {
    this.navActiveInit(1, '法律库', '知识库管理', '/laws')
  },
  destroyed() {
    this.navActiveInit(2, '法律库', '知识库管理', '/laws')
  },
  methods: {
    importDialogShow() {
      this.$refs.importLawsDetail.dialogVisible = true
    },
    getLawById() {
      GetLawById({ id: this.lawId }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.lawName = resData.data.lawName
        }
      })
    },
    back() {
      this.$router.push({
        path: '/laws',
      })
    },
    openDialog(saveType, row) {
      this.$refs.lawsSave.dialogVisible = true
      if (saveType === 2) {
        this.$refs.lawsSave.form.id = Number(row.id)
      }
    },
    delLaw(id) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        DeleteLawDetailsByIds({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter()
          } else {
            error(resData.msg)
          }
        })
      })
    },
    getList() {
      this.loading = true
      LawDetailsPage(this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
  },
}
</script>
