<template>
  <el-dialog
    :title="form.id ? '修改条纹' : '新增条纹'"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="40px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item
            label="编"
            prop="part"
          >
            <el-input
              v-model="form.part"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="章"
            prop="chapter"
          >
            <el-input
              v-model="form.chapter"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="节"
            prop="chapter"
          >
            <el-input
              v-model="form.section"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="条"
          >
            <el-input
              v-model="form.article"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="款"
          >
            <el-input
              v-model="form.paragraph"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="项"
          >
            <el-input
              v-model="form.subparagraph"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="目"
          >
            <el-input
              v-model="form.item"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item
            label="内容"
          >
            <el-input
              v-model="form.content"
              type="textarea"
            />
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { GetLawDetailsById, saveOrUpdateLawDetail } from '@/api/knowledgeBase/laws'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      typeCodeOptions: [],
      form: {
        article: '',
        chapter: '',
        content: '',
        id: 0,
        item: '',
        lawCode: this.$route.query.c,
        paragraph: '',
        part: '',
        section: '',
        subparagraph: '',
        title: '',
        unscramble: '',
      },
    }
  },
  methods: {
    getLawById() {
      GetLawDetailsById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = { ...resData.data }
        }
      })
    },
    dialogOpen() {
      if (this.form.id) {
        this.getLawById()
      }
    },
    dialogClose() {
      this.form = {
        article: '',
        chapter: '',
        content: '',
        id: 0,
        item: '',
        lawCode: this.$route.query.c,
        paragraph: '',
        part: '',
        section: '',
        subparagraph: '',
        title: '',
        unscramble: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (!this.form.id) {
            delete this.form.id
          }
          saveOrUpdateLawDetail(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.dialogVisible = false
              this.$emit('handleFilter')
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
